import * as React from 'react'
import { graphql } from 'gatsby'

import Page from '../components/Page'
import IndexLayout from '../layouts'
import ContactForm from '../components/contact/section'

import { Language } from '../state'
import { PxlRaisedButton } from '../components/Button'
import { PageLanguageFlags } from '../components/LanguageFlags'
import { Process } from '../Entities'

const ProcessHeader: React.SFC<{ title: string; subtitle: string }> = props => (
  <Language.Consumer>
    {language => (
      <div className="header">
        <div className="action banner-action">
          <PxlRaisedButton to={'/'} width={150}>
            {language.translate('ACTION_BACK')}
          </PxlRaisedButton>
          <PageLanguageFlags />
        </div>
        <div className="title">
          <h1>{props.title}</h1>
          <h3>{props.subtitle}</h3>
        </div>
      </div>
    )}
  </Language.Consumer>
)

const ProcessPage: React.SFC<{
  title: string
  subtitle: string
  services: {
    icon: { alt: string; copyright: string; url: string }
    title: string
    content: string
  }[]
}> = props => (
  <>
    <Page id="process-page">
      <ProcessHeader title={props.title} subtitle={props.subtitle} />
      <div className="details">
        {props.services.map((service, i) => (
          <div className="step section" key={i}>
            <img src={service.icon.url} />
            <h3>{service.title}</h3>
            <div dangerouslySetInnerHTML={{ __html: service.content }} />
          </div>
        ))}
      </div>
    </Page>
    <ContactForm />
  </>
)

type ProcessContainerProps = {
  data: {
    process: {
      edges: {
        node: Process
      }[]
    }
  }
}

const ProcessContainer: React.SFC<ProcessContainerProps> = props => {
  console.log(props)
  return (
    <IndexLayout>
      <Language.Consumer>
        {language => {
          const process = props.data.process.edges.find(
            edge => edge.node.lang.indexOf(language.selected) >= 0
          )
          if (!process) return null
          const data = process.node.data
          return (
            <ProcessPage
              title={data.title.text}
              subtitle={data.subtitle.text}
              services={data.service.map(s => ({
                icon: s.service_icon,
                title: s.title.text,
                content: s.content.html
              }))}
            />
          )
        }}
      </Language.Consumer>
    </IndexLayout>
  )
}

export default ProcessContainer

export const query = graphql`
  {
    process: allPrismicAboutCoffectiveProcess {
      edges {
        node {
          id
          lang
          dataString
          data {
            title {
              html
              text
            }
            subtitle {
              html
              text
            }
            plans_action_label
            service {
              title {
                html
                text
              }
              content {
                html
                text
              }
              service_icon {
                alt
                copyright
                url
              }
            }
          }
        }
      }
    }
  }
`
